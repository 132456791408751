<template>
  <div>
    <b-row>
      <b-col>
        <h1 class="font-weight-bolder">
          {{ $t('new', { type: $t('quote') }) }}
        </h1>
      </b-col>
    </b-row>
    <b-card
      no-body
      class="overflow-hidden"
    >
      <validation-observer ref="projectValidation">
        <b-row>
          <b-col>
            <b-card-body>
              <b-form @submit.prevent="validateForm()">
                <b-row>
                  <b-col
                    sm="12"
                    lg="6"
                  >
                    <b-form-group label="Folio">
                      <validation-provider
                        #default="{ errors }"
                        name="Folio"
                        rules="required"
                      >
                        <b-form-input
                          v-model="quote.folio"
                          size="lg"
                          class="font-weight-bolder"
                          autocomplete="off"
                          type="text"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col sm="12">
                    <b-form-group :label="$t('address')">
                      <gmap-autocomplete
                        ref="googleAddress"
                        class="form-control"
                        @place_changed="updateCenter($event)"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="d-flex justify-content-end">
                    <b-button
                      variant="primary"
                      size="lg"
                      class="px-5"
                      type="submit"
                    >
                      {{ $t('add', { type: $t('products') }) }}
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
            </b-card-body>
          </b-col>
          <b-col>
            <div id="root">
              <gmap-map
                ref="map"
                :center="center"
                :zoom="12"
                style="width: 100%; height: 750px"
              >
                <!-- TODO add pin -->
                <GmapMarker :position="marker" />
              </gmap-map>
            </div>
          </b-col>
        </b-row>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },

  data() {
    return {
      quote: {
        name: '',
        contact_name: '',
        quote_status: 0,
        project_id: null,
        is_published: false,
        is_invoiced: false,
        is_payed: false,
        is_shipped: false,
        address_attributes: {
          state: '',
          country: '',
          city: '',
          street: '',
          ext_number: '',
          postal_code: '',
          lat: '',
          lng: '',
          suburb: '',
        },
      },
      required,
      center: { lat: 25.6866142, lng: -100.3161126 },
      marker: {},
    }
  },
  computed: {
    ...mapGetters('suppliers', ['suppliers', 'newProviderFiles']),
  },
  created() {
    this.fetchProject(this.$route.params.projectId).then(response => {
      this.quote.address_attributes = { ...response.address }
      this.center = {
        lat: parseFloat(response.address.lat),
        lng: parseFloat(response.address.lng),
      }
      this.marker = {
        lat: parseFloat(response.address.lat),
        lng: parseFloat(response.address.lng),
      }
      const address = JSON.parse(JSON.stringify(response.address))
      this.$refs.googleAddress.$el.value = ` ${address.street}, ${address.ext_number}, ${address.postal_code}, ${address.city}, ${address.state} `
    })
  },
  methods: {
    ...mapActions('quotes', ['addQuote']),
    ...mapActions('projects', ['fetchProject']),
    updateCenter(place) {
      this.center = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      }
      this.marker = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      }
      this.companyAddress = place
      const address = {
        name: '',
        state: '',
        country: '',
        city: '',
        street: '',
        ext_number: '',
        int_number: '',
        postal_code: '',
        lat: '',
        lng: '',
        suburb: '',
        url: '',
        formatted_address: '',
      }
      place.address_components.forEach(x => {
        if (x.types.includes('administrative_area_level_1')) {
          address.state = x.long_name
        }
        if (x.types.includes('country')) {
          address.country = x.long_name
        }
        if (x.types.includes('city') || x.types.includes('locality')) {
          address.city = x.long_name
        }
        if (x.types.includes('street') || x.types.includes('route')) {
          address.street = x.long_name
        }
        if (
          x.types.includes('ext_number')
          || x.types.includes('street_number')
        ) {
          address.ext_number = x.long_name
        }
        if (x.types.includes('postal_code')) {
          address.postal_code = x.long_name
        }
        if (
          x.types.includes('suburb')
          || x.types.includes('sublocality_level_1')
        ) {
          address.suburb = x.long_name
        }
      })
      address.formatted_address = place.formatted_address
      address.lat = place.geometry.location.lat()
      address.lng = place.geometry.location.lng()
      this.quote.address_attributes = address
    },
    validateForm() {
      this.$refs.projectValidation.validate().then(success => {
        this.quote.project_id = this.$route.params.projectId
        if (success) {
          this.addQuote({
            ...this.quote,
          }).then(response => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'quoteCreated',
                icon: 'EditIcon',
                variant: 'success',
              },
            })
            this.$router.push({
              name: 'addProductsToQuote',
              params: { quoteId: response.id },
            })
          })
        }
      })
    },
  },
}
</script>

<style></style>
